import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../styles/App.css";
import Header from "./components/Header";
import CurrencyConverter from "./Tools/Calculators/CurrencyConverter";
import AdditionGenerator from "./Tools/PedagogicalTools/AdditionGenerator";
import SubtractionGenerator from "./Tools/PedagogicalTools/SubtractionGenerator";
import HomePage from "./Pages/HomePage";
import MultiplicationGenerator from "./Tools/PedagogicalTools/MultiplicationGenerator";
import CompoundInterestCalculator from "./Tools/Calculators/CompoundInterestCalculator";
import Wheel from "./Tools/OtherTools/FortuneWheel/YesOrNo";
import LuckyNumberGenerator from "./Tools/OtherTools/LuckyNumberGenerator";
import MeasureConverter from "./Tools/Calculators/MeasureConverter/MeasureConverter";
import CompoundInterestBRCalculator from "./Tools/Calculators/CompundInterestBRCalculator";
import Contact from "./Pages/Contact";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Pages/TermsOfService";
import CookieConsent from "./components/CookieConsent";

function App() {
  const { t, i18n } = useTranslation();

  const updateMetaDescription = () => {
    const descriptionMetaTag = document.querySelector('meta[data-i18n-description]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute('content', t('meta.description'));
    }
  };

  useEffect(() => {
    updateMetaDescription();
    i18n.on('languageChanged', updateMetaDescription);

    // Cleanup listener on unmount
    return () => {
      i18n.off('languageChanged', updateMetaDescription);
    };
  }, [i18n]);

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/conversao-moedas" element={<CurrencyConverter />} />
          <Route path="/juros-compostos" element={<CompoundInterestCalculator />} />
          <Route path="/juros-compostos-br" element={<CompoundInterestBRCalculator />} />
          <Route path="/conversao-medidas" element={<MeasureConverter />} />
          <Route path="/gerador-adicao" element={<AdditionGenerator />} />
          <Route path="/gerador-subtracao" element={<SubtractionGenerator />} />
          <Route path="/gerador-multiplicacao" element={<MultiplicationGenerator />} />
          <Route path="/roleta-sim-nao" element={<Wheel />} />
          <Route path="/gerador-numero-da-sorte" element={<LuckyNumberGenerator />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<header className="App-header"></header>} />
        </Routes>
      </div>
      <CookieConsent />
    </Router>
  );
}

export default App;
