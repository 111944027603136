import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Tooltip } from "@mui/material";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';

const PRINCIPAL_PLACEHOLDER = "compoundInterestCalculator.principalPlaceholder";
const INTEREST_RATE_PLACEHOLDER = "compoundInterestCalculator.interestRatePlaceholder";
const PERIOD_PLACEHOLDER = "compoundInterestCalculator.periodPlaceholder";

const CompoundInterestCalculator = () => {
  const { t } = useTranslation();
  const [principal, setPrincipal] = useState();
  const [interestRate, setInterestRate] = useState();
  const [period, setPeriod] = useState(1);
  const [showResult, setShowResult] = useState(false);
  const [compoundInterest, setCompoundInterest] = useState(null);
  const [totalAccumulated, setTotalAccumulated] = useState(null); // Nova variável de estado para armazenar o valor total acumulado
  const [resultDetails, setResultDetails] = useState([]);

  const handlePrincipalChange = ({ floatValue }) => {
    setPrincipal(floatValue || 0);
  };

  const handleInterestRateChange = ({ floatValue }) => {
    setInterestRate(floatValue || 0);
  };

  const handlePeriodChange = (event) => {
    setPeriod(parseInt(event.target.value, 10) || 1);
  };

  const calculateCompoundInterest = () => {
    const rate = interestRate / 100;
    const periods = period;

    let compoundInterestValue = 0;
    let totalAccumulatedValue = 0; // Variável para calcular o valor total acumulado

    const resultDetailsArray = [];

    for (let i = 1; i <= periods; i++) {
      const interest = parseFloat((principal * Math.pow(1 + rate, i - 1) * rate).toFixed(2));
      const value = parseFloat((principal * Math.pow(1 + rate, i) - principal).toFixed(2));

      compoundInterestValue = value;
      totalAccumulatedValue = principal + value; // Adiciona o valor principal mais o valor acumulado

      resultDetailsArray.push({
        period: i,
        principal: parseFloat(principal.toFixed(2)),
        interest: interest,
        value: value,
        total: parseFloat((principal + value).toFixed(2))
      });
    }

    setCompoundInterest(compoundInterestValue.toFixed(2));
    setTotalAccumulated(totalAccumulatedValue.toFixed(2)); // Atualiza o valor total acumulado
    setShowResult(true);
    setResultDetails(resultDetailsArray);
  };

  const downloadExcel = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Compound Interest Results');

    worksheet.addRow(["Period", "Interest", "Value", "Total"]);
    resultDetails.forEach((detail) => {
      worksheet.addRow([
        detail.period,
        detail.interest,
        detail.value,
        detail.total,
      ]);
    });

    const titleStyle = {
      font: { bold: true, size: 12, 'color': {'argb': 'FFFFFFFF'}},
      alignment: { horizontal: 'center' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ff04052e' }
      }
    };

    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: 'center' }
    };

    const numFmtStr = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';

    worksheet.getRow(1).font = titleStyle.font;
    worksheet.getRow(1).alignment = titleStyle.alignment;
    worksheet.getRow(1).eachCell((cell, colNumber) => {
      if (cell.value !== undefined && cell.value !== null) {
        cell.fill = titleStyle.fill;
      }
    });
    worksheet.getColumn(2).numFmt = numFmtStr;
    worksheet.getColumn(3).numFmt = numFmtStr;
    worksheet.getColumn(4).numFmt = numFmtStr;

    worksheet.columns = [
      { header: t("compoundInterestCalculator.table.period"), width: 10 },
      { header: t("compoundInterestCalculator.table.interest"), width: 30 },
      { header: t("compoundInterestCalculator.table.value"), width: 20 },
      { header: t("compoundInterestCalculator.table.total"), width: 20 }
    ];

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${t('compoundInterestCalculator.pdfTitle')}.xlsx`);
    });
  };

  return (
    <div className="compound-interest-calculator-container p-4">
      <div className="flex flex-col items-center mb-10">
        <h2 className="text-2xl font-bold mb-4 hover:underline transition duration-300 ease-in-out">
          {t("compoundInterestCalculator.title")}
        </h2>
        <p className="text-center max-w-md">{t("compoundInterestCalculator.pageDescription")}</p>
      </div>

      <div className="flex flex-col justify-center border-2 rounded-md p-4 space-y-2 mx-auto max-w-md">
        <div className="flex items-center justify-center mt-4">
          <Tooltip content={t("compoundInterestCalculator.tooltip.principal")}>
            <label htmlFor="principal" className="mr-2">
              {t(PRINCIPAL_PLACEHOLDER)}
            </label>
          </Tooltip>
          <NumericFormat
            id="principal"
            value={principal}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={handlePrincipalChange}
            className="border-2 border-gray-300 p-2 rounded-md w-full text-right"
            placeholder={t(PRINCIPAL_PLACEHOLDER)}
          />
          <BanknotesIcon className="ml-4 text-gray-500 h-10 w-10" />
        </div>

        <div className="flex items-center justify-between mt-6">
          <Tooltip
            content={t("compoundInterestCalculator.tooltip.interestRate")}
          >
            <label htmlFor="interestRate" className="mr-4">
              {t(INTEREST_RATE_PLACEHOLDER)}
            </label>
          </Tooltip>
          <NumericFormat
            id="interestRate"
            value={interestRate}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={handleInterestRateChange}
            className="border-2 border-gray-300 p-2 w-full rounded-md text-right"
            placeholder={t(INTEREST_RATE_PLACEHOLDER)}
          />
          <span className="ml-2 mr-2">%</span>
        </div>

        <div className="flex items-center justify-center mt-4">
          <Tooltip content={t("compoundInterestCalculator.tooltip.period")}>
            <label htmlFor="period" className="mr-2">
              {t(PERIOD_PLACEHOLDER)}
            </label>
          </Tooltip>
          <div className="flex flex-col justify-evenly">
            <NumericFormat
              id="period"
              value={period || 1}
              onValueChange={(values) =>
                setPeriod(parseInt(values.value, 10) || 1)
              }
              className="border-2 border-gray-300 p-2 rounded-md w-full text-right mt-4"
              placeholder={t(PERIOD_PLACEHOLDER)}
            />
            <span className="ml-2 mr-2 text-xs text-left">{t("compoundInterestCalculator.periodDetail")}</span>
          </div>
        </div>

        <button
          onClick={calculateCompoundInterest}
          className="bg-[#04052E] text-white p-2 rounded-md cursor-pointer mt-4"
        >
          {showResult
            ? t("compoundInterestCalculator.calculateAgain")
            : t("compoundInterestCalculator.calculate")}
        </button>
      </div>

      {showResult && (
        <div className="mt-4">
          <p className="text-lg font-bold m-6">
            {t("compoundInterestCalculator.result")}:{" "}
            <span className="text-green-700">
              {t("compoundInterestCalculator.resultCurrency")} {compoundInterest}{" "}
            </span>
          </p>
          
          <div className="mt-4">
            <p className="text-lg font-bold m-6">
              {t("compoundInterestCalculator.totalAccumulated")}:{" "}
              <span className="text-green-700">
                {t("compoundInterestCalculator.resultCurrency")} {totalAccumulated}
              </span>
            </p>
          </div>
          
          <div className="overflow-x-auto w-full mt-4">
            <table className="min-w-full border border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-500 p-2">{t("compoundInterestCalculator.table.period")}</th>
                  <th className="border border-gray-500 p-2">{t("compoundInterestCalculator.table.interest")}</th>
                  <th className="border border-gray-500 p-2">{t("compoundInterestCalculator.table.value")}</th>
                  <th className="border border-gray-500 p-2">{t("compoundInterestCalculator.table.total")}</th>
                </tr>
              </thead>
              <tbody>
                {resultDetails.map((detail) => (
                  <tr key={detail.period} className="bg-gray-100">
                    <td className="border border-gray-500 p-2">{detail.period}</td>
                    <td className="border border-gray-500 p-2">{detail.interest}</td>
                    <td className="border border-gray-500 p-2">{detail.value}</td>
                    <td className="border border-gray-500 p-2">{detail.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <button onClick={downloadExcel} className="bg-[#04052E] text-white p-2 rounded-md cursor-pointer mt-4">
        {t("common.downloadExcel")}
      </button>
    </div>
  );
};

export default CompoundInterestCalculator;
