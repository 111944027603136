import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import ExchangeRateComponent from "./ExchangeRate";
import { Button } from "@mui/material";

const CurrencyConverterComponent = () => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const [sourceCurrency, setSourceCurrency] = useState("USD");
  const [targetCurrency, setTargetCurrency] = useState("BRL");
  const [showLastConverted, setShowLastConverted] = useState(false);
  const [conversionHistory, setConversionHistory] = useState([]);
  const [lastConverted, setLastConverted] = useState(null);
  const [originalSourceCurrency, setOriginalSourceCurrency] = useState("USD");
  const [originalTargetCurrency, setOriginalTargetCurrency] = useState("BRL");
  const [exchangeRates, setExchangeRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [convertedAmounts, setConvertedAmounts] = useState([]);
  const [days, setDays] = useState(7);

  useEffect(() => {
    setOriginalSourceCurrency(sourceCurrency);
    setOriginalTargetCurrency(targetCurrency);
  }, []);

  const handleAmountChange = (values) => {
    const { floatValue } = values;
    setAmount(floatValue || 0);
  };

  const handleSourceCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;
    setSourceCurrency(selectedCurrency);
  };

  const handleTargetCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;
    setTargetCurrency(selectedCurrency);
  };

  const handleReverseCurrencies = () => {
    setSourceCurrency(targetCurrency);
    setTargetCurrency(sourceCurrency);
  };

  const filterTargetOptions = (selectedCurrency) => {
    return ["USD", "AUD", "BRL", "CAD", "CNY", "EUR", "GBP", "HKD", "JPY", "CHF", "MXN", "NZD", "SGD", "ZAR"].filter(currency => currency !== selectedCurrency);
  };

  const filterSourceOptions = (selectedCurrency) => {
    return ["USD", "AUD", "BRL", "CAD", "CNY", "EUR", "GBP", "HKD", "JPY", "CHF", "MXN", "NZD", "SGD", "ZAR"].filter(currency => currency !== selectedCurrency);
  };

  const handleConvert = async () => {
    try {
      const convertedValue = await convertCurrency(
        amount,
        sourceCurrency,
        targetCurrency
      );

      if (convertedValue !== null) {
        const newConversion = {
          amount,
          sourceCurrency,
          targetCurrency,
          convertedValue: parseFloat(convertedValue),
        };

        setLastConverted(newConversion);
        setConversionHistory((prevHistory) => [...prevHistory, newConversion]);
        setShowLastConverted(true);
      }

      await fetchExchangeRates();
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
    }
  };

  const convertCurrency = async (amount, sourceCurrency, targetCurrency) => {
    try {
      const response = await axios.get(
        `https://economia.awesomeapi.com.br/last/${sourceCurrency}-${targetCurrency}`
      );

      const exchangeRate =
        response.data[`${sourceCurrency}${targetCurrency}`].bid;
      const convertedValue = (amount * exchangeRate).toFixed(2);

      return convertedValue;
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      return null;
    }
  };

  const fetchExchangeRates = async () => {
    const currencyPair = `${sourceCurrency}-${targetCurrency}`;
    try {
      const response = await axios.get(
        `https://economia.awesomeapi.com.br/json/daily/${currencyPair}/${days}`
      );
      setExchangeRates(response.data);
      calculateConvertedAmounts(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const calculateConvertedAmounts = (exchangeRates) => {
    const amounts = exchangeRates
      .map((rate) => {
        if (rate && rate.ask) {
          const convertedAmount = (
            amount * parseFloat(rate.ask)
          ).toFixed(2);
          return { date: formatDate(rate.timestamp), convertedAmount };
        }
        return null;
      })
      .filter((amount) => amount !== null);

    setConvertedAmounts(amounts);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="p-4">
      <div className="flex flex-col items-center mb-10">
        <h2 className="text-2xl font-bold mb-4">
          {t("currencyConverter.title")}
        </h2>
        <p className="text-center max-w-md">{t("currencyConverter.pageDescription")}</p>
      </div>
      <div className="flex flex-col justify-center border-2 rounded-md p-4 space-y-2 mx-auto max-w-md">
        <div className="flex items-center justify-center mt-4">
          <NumericFormat
            placeholder={t("currencyConverter.valueLabel")}
            value={amount}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={handleAmountChange}
            className="border-2 border-gray-300 p-2 rounded-md w-full text-right"
          />
        </div>

        <div className="flex flex-col sm:flex-row items-center mt-6 mb-6 space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
          <div className="flex items-center">
            <select
              id="source-currency"
              name="source-currency"
              value={sourceCurrency}
              onChange={handleSourceCurrencyChange}
              data-testid="source-currency-select"
              className="border-2 border-gray-300 p-2 rounded-md w-32"
            >
              {filterSourceOptions(targetCurrency).map(currency => (
                <option key={currency} value={currency}>
                  {currency} - {t(`currency.${currency}`)}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center">
            <Button
              onClick={handleReverseCurrencies}
              className="text-[#04052E] text-sm h-8 w-8 focus:outline-none"
            >
              <ArrowsRightLeftIcon className="text-[#04052E] h-7 w-7"/>
            </Button>
          </div>

          <div className="flex items-center">
            <select
              id="target-currency"
              name="target-currency"
              value={targetCurrency}
              onChange={handleTargetCurrencyChange}
              data-testid="target-currency-select"
              className="border-2 border-gray-300 p-2 rounded-md w-32"
            >
              {filterTargetOptions(sourceCurrency).map(currency => (
                <option key={currency} value={currency}>
                  {currency} - {t(`currency.${currency}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          onClick={handleConvert}
          className="bg-[#04052E] text-white p-2 rounded-md cursor-pointer"
        >
          {t("currencyConverter.convert")}
        </button>
        <span className="text-xs text-center">
          {t("currencyConverter.disclaimer")}
        </span>
        <div className="flex justify-center mt-4">
          <input
            type="checkbox"
            id="showLastConverted"
            checked={showLastConverted}
            onChange={() => setShowLastConverted(!showLastConverted)}
          />
          <label htmlFor="showLastConverted" className="ml-2">
            {t("currencyConverter.showLastValues")}
          </label>
        </div>
      </div>
      {lastConverted && (
        <div className="mt-4">
          <h3 className="mb-2 font-bold">
            {t("currencyConverter.updatedValue")}
          </h3>
          <p>{`${lastConverted.amount} ${lastConverted.sourceCurrency} = ${lastConverted.convertedValue} ${lastConverted.targetCurrency}`}</p>
        </div>
      )}
      {showLastConverted && (
        <div className="mt-4">
          {conversionHistory.length > 0 && (
            <div className="mt-4">
              <h3 className="mb-2 font-bold">
                {t("currencyConverter.lastValues")}
              </h3>
              <ul className="space-y-1">
                {conversionHistory
                  .slice()
                  .reverse()
                  .map((conversion, index) => (
                    <li key={index}>
                      {conversion.amount} {conversion.sourceCurrency} ={" "}
                      {conversion.convertedValue} {conversion.targetCurrency}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      )}

      <ExchangeRateComponent
        amount={amount}
        sourceCurrency={sourceCurrency}
        targetCurrency={targetCurrency}
        exchangeRates={exchangeRates}
        convertedAmounts={convertedAmounts}
        loading={loading}
        error={error}
        days={days}
        setDays={setDays}
      />
    </div>
  );
};

export default CurrencyConverterComponent;
