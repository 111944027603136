import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Container, Typography, Box, Paper, Grid } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

function Contact() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    phone: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const navigate = useNavigate();
  const userID = process.env.REACT_APP_USER_ID;
  const templateID = process.env.REACT_APP_TEMPLATE_ID;
  const serviceID = process.env.REACT_APP_SERVICE_ID;

  const goToCookiesPolicy = () => {
    navigate("/terms-of-service", { replace: true });
  };

  const goToPrivacyPolicy = () => {
    navigate("/privacy-policy", { replace: true });
  };

  const maxDigits = 20;

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setCountryCode(data.country_code.toLowerCase());
      } catch (error) {
        console.error('Error fetching user location:', error);
      }
    };

    fetchUserLocation();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value
    }));
  };

  const isValidPhoneNumber = (inputNumber) => {
    const digits = inputNumber.replace(/\D/g, '').length;
    return digits <= maxDigits;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      await emailjs.send(serviceID, templateID, formData, userID);
      setSuccess(true);
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        phone: ''
      });
    } catch (err) {
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 8, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} sx={{ pr: { md: 4 } }}>
          <Typography 
            variant="h4" 
            fontFamily="sans-serif" 
            component="h1" 
            mb={3}
            sx={{
              fontSize: {
                xs: '1.5rem',  // small screens
                sm: '2rem',    // medium screens
                md: '2.5rem',  // large screens
                lg: '3rem'     // extra large screens
              }
            }}
          >
            {t('contact.contact_us')}
          </Typography>
          <Typography 
            textAlign="justify" 
            fontSize="18px" 
            fontFamily="sans-serif" 
            variant="body1" 
            mb={3}
            sx={{
              fontSize: {
                xs: '0.875rem',  // small screens
                sm: '1rem',      // medium screens
                md: '1.125rem',  // large screens
                lg: '1.25rem'    // extra large screens
              }
            }}
          >
            {t('contact.pageDescription1')}
          </Typography>
          <Box 
            component="pre" 
            fontFamily="sans-serif" 
            fontSize="18px" 
            textAlign="justify" 
            mb={3} 
            sx={{ whiteSpace: 'pre-line',
              fontSize: {
                xs: '0.875rem',  // small screens
                sm: '1rem',      // medium screens
                md: '1.125rem',  // large screens
                lg: '1.25rem'    // extra large screens
              } }}
          >
            {t('contact.pageDescription2')}
          </Box>
          <Box 
            component="pre" 
            fontFamily="sans-serif" 
            fontSize="18px" 
            textAlign="justify" 
            fontWeight={"bold"}
            sx={{ whiteSpace: 'pre-line',
              fontSize: {
                xs: '0.875rem',  // small screens
                sm: '1rem',      // medium screens
                md: '1.125rem',  // large screens
                lg: '1.25rem'    // extra large screens
              } }}
          >
            {t('contact.pageDescription3')}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 4 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('contact.name')}
                name="name"
                value={formData.name}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                required
                sx={{ fontSize: {
                xs: '0.875rem',  // small screens
                sm: '1rem',      // medium screens
                md: '1.125rem',  // large screens
                lg: '1.25rem'    // extra large screens
              } }}
              />
              <TextField
                fullWidth
                label={t('contact.email')}
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                required
              />
              <Box sx={{ mt: 2, mb: 2 }}>
                <PhoneInput
                  country={countryCode}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  isValid={isValidPhoneNumber}
                  inputProps={{
                    name: 'phone',
                    required: false,
                    autoFocus: false
                  }}
                  inputStyle={{
                    width: '100%',
                    padding: '18.5px 14px',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    fontSize: '1rem',
                    height: "60px",
                    paddingLeft: "50px",
                  }}
                  containerStyle={{ width: '100%' }}
                  buttonStyle={{
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px 0 0 4px',
                    backgroundColor: '#fafafa'
                  }}
                  dropdownStyle={{
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '0 0 4px 4px'
                  }}
                />
              </Box>
              <TextField
                fullWidth
                label={t('contact.subject')}
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                required
                sx={{ fontSize: {
                xs: '0.875rem',  // small screens
                sm: '1rem',      // medium screens
                md: '1.125rem',  // large screens
                lg: '1.25rem'    // extra large screens
              } }}
              />
              <TextField
                fullWidth
                label={t('contact.message')}
                name="message"
                value={formData.message}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                multiline
                rows={4}
                required
                sx={{ fontSize: {
                xs: '0.875rem',  // small screens
                sm: '1rem',      // medium screens
                md: '1.125rem',  // large screens
                lg: '1.25rem'    // extra large screens
              } }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  sx={{ backgroundColor: '#04052E', color: 'white', p: '16px 40px', borderRadius: '8px', cursor: 'pointer' }}
                  type="submit"
                  variant="contained"
                  disabled={loading}
                >
                  {t('contact.send')}
                </Button>
              </Box>
              {success && <Typography color="success.main">{t('contact.success_message')}</Typography>}
              {error && <Typography color="error.main">{t('contact.error_message')}</Typography>}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 6 }}>
        <Button
          sx={{ color: '#04052E', p: '4px 40px', borderRadius: '8px', cursor: 'pointer', fontWeight: 'light' }}
          onClick={goToPrivacyPolicy}
        >
          {t('contact.privacy_button')}
        </Button>
        <Button
          sx={{ color: '#04052E', p: '4px 40px', borderRadius: '8px', cursor: 'pointer', fontWeight: 'light' }}
          onClick={goToCookiesPolicy}
        >
          {t('contact.cookie_button')}
        </Button>
      </Box>
    </Container>
  );
}

export default Contact;
