import React from "react";
import { useTransition } from "react-spring";
import { motion } from "framer-motion";

const MessageTransition = ({ resultMessage }) => {
  const transitions = useTransition(resultMessage, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
  });

  return transitions((styles, item) => (
    <motion.div variants={messageVariants} style={styles}>
      {item}
    </motion.div>
  ));
};

const messageVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

export default MessageTransition;
