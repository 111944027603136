import React from "react";
import { useNavigate } from "react-router-dom";
import useMenuItems from "./menuitems/menuItems";

function handleMenuItemClick(navigate, item) {
  navigate(item.link);
}

function HomepageMenuItem({ item }) {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center mt-4">
      <button
        className="flex flex-col items-center h-full w-full p-4 bg-[#04052E] rounded-lg shadow-xl hover:bg-gray-100 text-white hover:text-[#04052E] transition duration-300 ease-in-out transform hover:scale-105"
        onClick={() => handleMenuItemClick(navigate, item)}
      >
        <div className="flex items-center justify-center h-10 w-10 mb-2 text-[#EC9A29]">
          {React.createElement(item.icon)}
        </div>
        <div className="flex flex-1 justify-center items-center text-center">{item.name}</div>
      </button>
    </div>
  );
}

function HomepageMenu() {
  const { allMenuItems } = useMenuItems();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-auto max-w-4xl p-4 z-20">
      {allMenuItems.map((item, index) => (
        <HomepageMenuItem key={index} item={item} />
      ))}
    </div>
  );
}

export default HomepageMenu;
