import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import BackgroundTransition from "./BackgroundTransition";
import MessageTransition from "./MessageTransition";
import { HandThumbUpIcon, HandThumbDownIcon } from "@heroicons/react/24/solid";

const ResultAnimation = ({ result, onDismiss }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const Icon = ({ result }) => {
    if (result === t("spinWheel.yes")) {
      return <HandThumbUpIcon />;
    } else if (result === t("spinWheel.no")) {
      return <HandThumbDownIcon />;
    }
    return null;
  };

  const resultMessage = `${t(`${result}`)}`;
  const resultDescription = t("spinWheel.resultPrefix");

  // Animação de entrada: fade in e scale up
  const containerVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      background: "rgba(255, 255, 255, 0)", // Fundo branco transparente
    },
    visible: {
      opacity: 1,
      scale: 1,
      background: "rgba(255, 255, 255, 0.94)", // Fundo branco com opacidade
      transition: {
        delay: 0.5, // Espera meio segundo após a roda parar
        duration: 0.5,
      },
    },
    onComplete: onDismiss,
  };

  const handleDismiss = () => {
    setIsVisible(false);
    onDismiss(); // Chama a função fornecida para esconder o resultado
  };

  return (
    isVisible && (
      <motion.div
        className="flex justify-center rounded-full "
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        style={{
          position: "absolute",
          width: "50%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={handleDismiss} // Adiciona um clique para esconder o resultado
      >
        <BackgroundTransition result={result} />
        <div className="flex flex-col justify-center space-y-2">
          <div className="text-md">
            <MessageTransition resultMessage={resultDescription} />
          </div>
          <div className="font-extrabold text-[42px]">
            <MessageTransition resultMessage={resultMessage} />
          </div>
          <Icon result={result} />
        </div>
      </motion.div>
    )
  );
};

export default ResultAnimation;
