import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';

function LuckyNumberGenerator() {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(5);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(10);
  const [luckyNumbers, setLuckyNumbers] = useState([]);
  const [lastDrawNumbers, setLastDrawNumbers] = useState([]);
  const [error, setError] = useState('');
  const [showLastNumbers, setShowLastNumbers] = useState(false);
  const [repeatNumbers, setRepeatNumbers] = useState(false);

  const generateUniqueNumber = (start, end, exclude, lastDrawNumbers, repeatNumbers) => {
    let availableNumbers = filter(
      Array.from({ length: end - start + 1 }, (_, i) => start + i),
      (number) => {
        if (repeatNumbers) {
          return !exclude.includes(number);
        } else {
          return !exclude.includes(number) && !checkIfNumberRepeated(number, lastDrawNumbers);
        }
      }
    );
  
    if (availableNumbers.length === 0) {
      setError(t('luckyNumbers.errors.allNumbersDrawn'));
      return;
    }
  
    let number, tries = 0;
    do {
      number = availableNumbers[Math.floor(Math.random() * availableNumbers.length)];
      tries++;
    } while (exclude.includes(number) && tries < 100);
  
    if (repeatNumbers === false && checkIfNumberRepeated(number, lastDrawNumbers)) {
      setError(t('luckyNumbers.errors.alreadyIncluded'));
      return;
    }
  
    if (number < start || number > end) {
      setError(t('luckyNumbers.errors.outOfRange'));
      return;
    }
  
    setError(''); // Limpa erros após a geração bem-sucedida
    return number;
  };

  const checkIfNumberRepeated = (number, lastDrawNumbers) => {
    for (let i = 0; i < lastDrawNumbers.length; i++) {
      if (lastDrawNumbers[i].includes(number)) {
        return true;
      }
    }
    return false;
  };

  const animateNumbers = (numbers, onComplete) => {
    let count = 0; // Contador para rastrear a conclusão da animação
    numbers.forEach((number, index) => {
      setTimeout(() => {
        setLuckyNumbers((prev) => [...prev, number]);
        count++;
        if (count === numbers.length) {
          onComplete(); // Chama o callback após o último número ser animado
        }
      }, index * 500); // Delay baseado no índice para efeito de sequência
    });
  };

  const generateLuckyNumbers = () => {
    if (start >= end) {
      setError(t('luckyNumbers.errors.startEndRange'));
      return;
    }

    if (quantity > end - start + 1) {
      setError(t('luckyNumbers.errors.quantityRange'));
      return;
    }

    setError(''); // Limpa erros anteriores
    setLuckyNumbers([]); // Prepara para nova animação, limpa números anteriores

    let numbers = [];
    for (let i = 0; i < quantity; i++) {
      let num = generateUniqueNumber(start, end, numbers, lastDrawNumbers, repeatNumbers);
      if (num) {
        numbers.push(num);
      }
    }

    // Animação dos números com atualização de estado após conclusão
    animateNumbers(numbers, () => {
      // Callback após a conclusão da animação
      if (showLastNumbers) {
        setLastDrawNumbers(prev => [...prev, numbers]);
      } else {
        setLastDrawNumbers(prev => [...prev, [...numbers]]);
      }
    });
  };



  return (
    <div className="flex-1 justify-center p-4">
       <div className="text-center mb-10">
        <h2 className="text-2xl font-bold mb-4">
          {t("luckyNumbers.title")}
        </h2>
        <p className="max-w-prose mx-auto">{t("luckyNumbers.pageDescription")}</p>
      </div>

      <div className="flex flex-col justify-center border-2 rounded-md p-4 space-y-4 mr-auto ml-auto max-w-md">
        {error && <p className="text-red-500">{error}</p>}
        <div className="flex flex-col space-y-4 mb-4">
        <div className='flex justify-between'>
          <label className="flex justify-center items-center text-left whitespace-pre-line">
            {t('luckyNumbers.quantity')}
          </label>
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value, 10) || 0))}
            min="1"
            className="border p-2 max-w-20"
          />
          </div>
          <div className='flex flex-row justify-between w-full'>
            <label className="flex justify-center items-center text-center whitespace-pre-line">
              {t('luckyNumbers.start')}
            </label>
            <input
              type="number"
              value={start}
              onChange={(e) => setStart(parseInt(e.target.value, 10) || 0)}
              className="border p-2 max-w-20"
            />
            <label className="flex justify-center items-center text-center whitespace-pre-line">
              {t('luckyNumbers.end')}
            </label>
            <input
              type="number"
              value={end}
              onChange={(e) => setEnd(parseInt(e.target.value, 10) || 0)}
              className="border p-2 max-w-20"
            />
          </div>
          <div className='flex flex-row justify-between w-full'>
            <label className="flex justify-center items-center text-center whitespace-pre-line">
              {t('luckyNumbers.showLastNumbers')}
            </label>
            <input
              type="checkbox"
              checked={showLastNumbers}
              onChange={() => setShowLastNumbers(!showLastNumbers)}
              className="flex h-4 w-4 border p-2 mr-8"
            />
          </div>
          {showLastNumbers && (
            <div className="flex justify-between items-center">
              <label className="text-left">
                {t('luckyNumbers.repeatNumbers')}
              </label>
              <select
                value={repeatNumbers}
                onChange={(e) => setRepeatNumbers(e.target.value === 'true')}
                className="border p-2 max-w-20"
              >
                <option value="true">{t('luckyNumbers.yes')}</option>
                <option value="false">{t('luckyNumbers.no')}</option>
              </select>
            </div>
          )}
          <button
            onClick={generateLuckyNumbers}
            className="bg-[#04072e] text-white p-2 rounded"
          >
            {t('luckyNumbers.generate')}
          </button>
        </div>
      </div>

      <div className="flex justify-center mt-10">
        <AnimatePresence>
          <div className="flex flex-wrap justify-center">
            {luckyNumbers.map((number, index) => (
              <motion.div
                key={index}
                initial={{ scale: 0, opacity: 0 }}
                animate={{ rotate: 360, scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="w-16 h-16 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-white from-20% via-[#FE9000] via-95% to-[#FE9000] to-95% text-[#04072e] font-bold flex items-center shadow-xl justify-center m-2 rounded-full"
              >
                {number}
              </motion.div>
            ))}
          </div>
        </AnimatePresence>
      </div>

      {showLastNumbers && (
        <div className="flex flex-col items-center mt-10">
          <h3 className="text-xl font-bold mb-4">{t("luckyNumbers.lastNumbers")}</h3>
          <div className="space-y-3">
            {lastDrawNumbers.map((numbers, drawIndex) => (
              <div key={drawIndex} className="space-y-2">
                <p className="text-lg font-light mb-2">{`Sorteio ${drawIndex + 1}`}</p>
                <div className="flex flex-wrap justify-center">
                  {numbers.map((number, index) => (
                    <div 
                      key={index}
                      className="w-8 h-8 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-white from-20% via-[#5BC0BE] via-95% to-[#5BC0BE] to-95% text-[#04072e] text-md font-bold flex items-center shadow-xl justify-center m-2 rounded-full"
                    >
                      {number}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default LuckyNumberGenerator;