import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { Tooltip } from "@mui/material";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';
import customStyles from './CustomStyle_reactSelect.tsx';
import ReactLoading from 'react-loading';

const PRINCIPAL_PLACEHOLDER = "compoundInterestCalculatorBR.principalPlaceholder";
const INTEREST_RATE_PLACEHOLDER = "compoundInterestCalculatorBR.interestRatePlaceholder";

const CompoundInterestBRCalculator = () => {
  const { t } = useTranslation();
  const [principal, setPrincipal] = useState();
  const [period, setPeriod] = useState(1);
  const [showResult, setShowResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalInterest, setTotalInterest] = useState();
  const [totalAccumulated, setTotalAccumulated] = useState(null);
  const [resultDetails, setResultDetails] = useState([]);
  const [selectedStandardRates, setSelectedStandardRates] = useState([]);
  const [selectedRateCodes, setSelectedRateCodes] = useState([]);
  const [customRate, setCustomRate] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [calculatedRates, setCalculatedRates] = useState([]);
  const [showCustomRateColumn, setShowCustomRateColumn] = useState(false);

  const standardInterestRates = [
    { name: "Selic", code: "4390" },
    { name: "IGP-M", code: "28655" },
    { name: "Poupança", code: "196" },
    { name: "TR", code: "7811" },
    { name: "CDI", code: "12" },
  ];

  const animatedComponents = makeAnimated();

  const handleStartDateChange = (e) => {
    setSelectedStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setSelectedEndDate(new Date(e.target.value));
  };

  const handleStandardRateChange = (selectedOptions) => {
    setSelectedStandardRates(selectedOptions);
    setSelectedRateCodes(selectedOptions ? selectedOptions.map(option => option.code) : []);
  };

  const handleCustomRateChange = ({ floatValue }) => {
    setCustomRate(floatValue || 0);
  };

  const validateInputs = () => {
    if (!principal || principal <= 0) {
      alert("Por favor, insira um valor principal válido.");
      return false;
    }
    if (!selectedStartDate) {
      alert("Por favor, selecione uma data de início.");
      return false;
    }
    if (!selectedEndDate) {
      alert("Por favor, selecione uma data de término.");
      return false;
    }
    if (selectedEndDate <= selectedStartDate) {
      alert("A data de término deve ser posterior à data de início.");
      return false;
    }
    if (selectedStandardRates.length === 0 && customRate === 0) {
      alert("Por favor, selecione ao menos uma taxa de juros padrão ou insira uma taxa personalizada.");
      return false;
    }
    return true;
  };

  const calculateCompoundInterest = async () => {
    if (!validateInputs()) {
      return;
    }
  
    setIsLoading(true);
    setShowResult(false);
    setShowCustomRateColumn(customRate > 0);
  
    let totalAccumulatedValue = principal;
    const resultDetailsArray = [];
    let currentPrincipal = principal;
    let totalInterestValue = 0;
  
    const calculateInterestForRate = async (rate) => {
      try {
        const formattedStartDate = selectedStartDate?.toISOString().split('T')[0];
        const formattedEndDate = selectedEndDate?.toISOString().split('T')[0];
  
        const response = await fetch(`https://api.bcb.gov.br/dados/serie/bcdata.sgs.${rate.value}/dados?formato=json&dataInicial=${formattedStartDate}&dataFinal=${formattedEndDate}`);
        const contentType = response.headers.get('content-type');
  
        const data = await response.json();
        if (!Array.isArray(data)) {
          throw new Error('Dados inválidos recebidos da API.');
        }
  
        const startDate = new Date(formattedStartDate);
        const endDate = new Date(formattedEndDate);
  
        const filteredData = data.filter(entry => {
          const entryDate = new Date(entry.data.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
          return entryDate >= startDate && entryDate <= endDate;
        });
  
        return filteredData.map(entry => ({
          date: new Date(entry.data.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')),
          rate: parseFloat(entry.valor) / 100
        }));
  
      } catch (error) {
        console.error(`Erro ao obter dados para a taxa ${rate.label}:`, error);
        alert(`Erro ao obter dados para a taxa ${rate.label}. Por favor, tente novamente.`);
        return [];
      }
    };
  
    const rateData = await Promise.all(selectedStandardRates.map(rate => calculateInterestForRate(rate)));
  
    if (!rateData || rateData.length === 0) {
      alert("Não foi possível obter os dados das taxas de juros.");
      setIsLoading(false);
      return;
    }
  
    const combinedRateData = rateData.flat().sort((a, b) => a.date - b.date);
  
    let period = 1;
    const uniqueDates = [...new Set(combinedRateData.map(item => item.date.toISOString().split('T')[0]))];
  
    for (const dateStr of uniqueDates) {
      const date = new Date(dateStr);
      const dailyInterests = rateData.map(data => {
        const rateDataForDate = data.find(entry => entry.date.toISOString().split('T')[0] === dateStr);
        return rateDataForDate ? rateDataForDate.rate : 0;
      });
  
      if (customRate) {
        dailyInterests.push(customRate / 100);
      }
  
      const interestValue = dailyInterests.map(rate => currentPrincipal * rate);
  
      const totalDailyInterest = interestValue.reduce((sum, val) => sum + val, 0);
      totalInterestValue += totalDailyInterest;
  
      currentPrincipal += totalDailyInterest;
  
      resultDetailsArray.push({
        period: period++,
        date: date,
        principal: parseFloat(currentPrincipal.toFixed(2)),
        interestRates: dailyInterests,
        interestValues: interestValue.map(val => parseFloat(val.toFixed(2))),
        total: parseFloat(currentPrincipal.toFixed(2)),
        customRateValue: customRate ? parseFloat((currentPrincipal * (customRate / 100)).toFixed(2)) : null
      });
    }
  
    setTotalAccumulated(currentPrincipal.toFixed(2));
    setTotalInterest(totalInterestValue.toFixed(2));
    setShowResult(true);
    setResultDetails(resultDetailsArray);
    setCalculatedRates(selectedStandardRates);
    setIsLoading(false);
  };
  

  const formatDate = (date) => {
    const isoString = date.toISOString();
    const [year, month, day] = isoString.split('T')[0].split('-');
    return `${day}/${month}/${year}`;
  };

  const downloadExcel = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Compound Interest Results');

    worksheet.addRow(["Period", "Date", ...calculatedRates.map(rate => rate.label), showCustomRateColumn ? "Taxa Adicional" : null, "Interest Values", "Total"]);
    resultDetails.forEach((detail) => {
      worksheet.addRow([
        detail.period,
        formatDate(detail.date),
        ...detail.interestValues.map(value => value.toFixed(2)),
        detail.interestValues.reduce((a, b) => a + b, 0).toFixed(2),
        detail.total,
      ]);
    });

    const titleStyle = {
      font: { bold: true, size: 12, color: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: 'center' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ff04052e' }
      }
    };

    const numFmtStr = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';

    worksheet.getRow(1).font = titleStyle.font;
    worksheet.getRow(1).alignment = titleStyle.alignment;
    worksheet.getRow(1).eachCell((cell, colNumber) => {
      if (cell.value !== undefined && cell.value !== null) {
        cell.fill = titleStyle.fill;
      }
    });
    worksheet.getColumn(2).numFmt = numFmtStr;
    worksheet.getColumn(3).numFmt = numFmtStr;
    worksheet.getColumn(4).numFmt = numFmtStr;

    worksheet.columns = [
      { header: t("compoundInterestCalculatorBR.table.period"), width: 10 },
      { header: t("compoundInterestCalculatorBR.table.date"), width: 30 },
      ...calculatedRates.map(rate => ({ header: rate.label, width: 20 })),
      { header: t("compoundInterestCalculatorBR.table.customRate"), width: 20 },
      { header: t("compoundInterestCalculatorBR.table.value"), width: 20 },
      { header: t("compoundInterestCalculatorBR.table.total"), width: 20 }
    ];

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${t('compoundInterestCalculatorBR.pdfTitle')}.xlsx`);
    });
  };

  return (
    <div className="compound-interest-calculator-container p-4">
      <div className="flex flex-col items-center mb-10">
        <h2 className="text-2xl font-bold mb-4 hover:underline transition duration-300 ease-in-out">
          {t("compoundInterestCalculatorBR.title")}
        </h2>
        <p className="text-center max-w-md">{t("compoundInterestCalculatorBR.pageDescription")}</p>
      </div>

      <div className="flex flex-col justify-center border-2 rounded-md p-4 space-y-2 mx-auto max-w-md">
        <div className="flex flex-col items-center justify-center mt-4 space-y-4">
          <div className="flex items-center w-full">
            <Tooltip title={t("compoundInterestCalculatorBR.tooltip.principal")}>
              <label htmlFor="principal" className="w-1/3 text-left">
                {t(PRINCIPAL_PLACEHOLDER)}
              </label>
            </Tooltip>
            <NumericFormat
              id="principal"
              value={principal}
              thousandSeparator="."
              decimalSeparator=","
              onValueChange={(values) => setPrincipal(values.floatValue || 0)}
              className="border-2 border-gray-200 p-2 rounded-md w-2/3 text-right ml-2"
              placeholder={t(PRINCIPAL_PLACEHOLDER)}
            />
            <BanknotesIcon className="ml-4 text-gray-500 h-10 w-10" />
          </div>

          <div className="flex items-center w-full">
            <Tooltip title={t("compoundInterestCalculatorBR.tooltip.interestRate")}>
              <label htmlFor="interestRate" className="w-1/3 text-left">
                {t(INTEREST_RATE_PLACEHOLDER)}
              </label>
            </Tooltip>
            <NumericFormat
              id="interestRate"
              value={customRate}
              thousandSeparator="."
              decimalSeparator=","
              onValueChange={handleCustomRateChange}
              className="border-2 border-gray-200 p-2 rounded-md w-2/3 text-right ml-2"
              placeholder={t(INTEREST_RATE_PLACEHOLDER)}
            />
            <p className="ml-4 text-gray-500 font-bold">(%)</p>
          </div>

          <div className="flex items-center w-full">
            <label htmlFor="standardRates" className="w-1/3 text-left">
              {t("compoundInterestCalculatorBR.standardRates")}
            </label>
            <Select
              id="standardRates"
              isMulti
              className="border-2 border-gray-200 rounded-md w-2/3 text-center ml-2"
              closeMenuOnSelect={false}
              components={animatedComponents}
              options={standardInterestRates.map((rate) => ({ label: rate.name, value: rate.code }))}
              styles={customStyles}
              onChange={handleStandardRateChange}
              value={selectedStandardRates}
              placeholder={t("compoundInterestCalculatorBR.selectPlaceholder")}
            />
          </div>

          <div className="flex items-center w-full">
            <label htmlFor="startDate" className="w-1/3 text-left">
              {t("compoundInterestCalculatorBR.startDate")}
            </label>
            <input
              type="date"
              id="startDate"
              className="border-2 border-gray-200 p-2 rounded-md w-2/3 ml-2"
              onChange={handleStartDateChange}
            />
          </div>

          <div className="flex items-center w-full">
            <label htmlFor="endDate" className="w-1/3 text-left">
              {t("compoundInterestCalculatorBR.endDate")}
            </label>
            <input
              type="date"
              id="endDate"
              className="border-2 border-gray-200 p-2 rounded-md w-2/3 ml-2"
              onChange={handleEndDateChange}
            />
          </div>
        </div>

        <button
          onClick={calculateCompoundInterest}
          className="bg-[#04052E] text-white p-2 rounded-md mt-4"
          disabled={isLoading}
        >
          {isLoading ? t("compoundInterestCalculatorBR.calculating") 
          : t("compoundInterestCalculatorBR.calculate")}
        </button>
      </div>

      {isLoading && (
        <div className="flex justify-center mt-4">
          <div className="loader">
            <ReactLoading type={"spokes"} bgColor={null} color={"#04052E"} height={40} width={40} />
          </div>
        </div>
      )}

      {showResult && (
        <div className="mt-4 p-4 rounded-md">
          <h3 className="font-bold mb-2">{t("compoundInterestCalculatorBR.resultTitle")}</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full border border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-500 p-2">
                    {t("compoundInterestCalculatorBR.table.period")}
                  </th>
                  <th className="border border-gray-500 p-2">
                    {t("compoundInterestCalculatorBR.table.date")}
                  </th>
                  {calculatedRates && calculatedRates.map(rate => (
                    <th key={rate.value} className="border border-gray-500 p-2">
                      {rate.label}
                    </th>
                  ))}
                  {showCustomRateColumn && (
                    <th className="border border-gray-500 p-2">
                      {t("compoundInterestCalculatorBR.table.customRate")}
                    </th>
                  )}
                  <th className="border border-gray-500 p-2">
                    {t("compoundInterestCalculatorBR.table.value")}
                  </th>
                  <th className="border border-gray-500 p-2">
                    {t("compoundInterestCalculatorBR.table.total")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultDetails.map((detail, index) => (
                  <tr key={index} className="bg-gray-100">
                    <td className="border border-gray-500 p-2">
                      {detail.period}
                    </td>
                    <td className="border border-gray-500 p-2">
                      {formatDate(detail.date)} 
                    </td>
                    {detail.interestValues && detail.interestValues.map((value, i) => (
                      <td key={i} className="border border-gray-500 p-2">
                        {value.toFixed(2)}
                      </td>
                    ))}
                    <td className="border border-gray-500 p-2">{detail.interestValues.reduce((a, b) => a + b, 0).toFixed(2)}</td>
                    <td className="border border-gray-500 p-2">{detail.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            onClick={downloadExcel}
            className="bg-[#04052E] text-white p-2 rounded-md cursor-pointer mt-4"
          >
            {t("common.downloadExcel")}
          </button>
        </div>
      )}
    </div>
  );
}

export default CompoundInterestBRCalculator;
