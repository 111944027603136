import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import pt from './locales/pt.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';

i18n
  .use(LanguageDetector) // Usar o detector de idioma do navegador
  .use(initReactI18next)
  .init({
    resources: {
      pt: {
        translation: pt,
      },
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      fr: {
        translation: fr,
      },
    },
    fallbackLng: 'pt', // Idioma padrão caso o idioma do navegador não esteja disponível
    interpolation: {
      escapeValue: false, // React já protege contra XSS
    },
  });

export default i18n;
