import { useTranslation } from 'react-i18next';
import {
  CurrencyDollarIcon,
  ReceiptPercentIcon,
  ArrowsRightLeftIcon,
  CalculatorIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";

const useMenuItems = () => {
  const { t } = useTranslation();

  const calculatorMenuItems = [
    {
      name: t('currencyConverter.title'),
      link: "/conversao-moedas",
      icon: CurrencyDollarIcon,
    },
    {
      name: t('compoundInterestCalculator.title'),
      link: "/juros-compostos",
      icon: ReceiptPercentIcon,
    },
    {
      name: t('compoundInterestCalculatorBR.title'),
      link: "/juros-compostos-br",
      icon: ReceiptPercentIcon,
    },
    {
      name: t('measureConverter.title'),
      link: "/conversao-medidas",
      icon: ArrowsRightLeftIcon,
    },
  ];

  const pedagogicalToolsMenuItems = [
    {
      name: t('additionGenerator.title'),
      link: "/gerador-adicao",
      icon: CalculatorIcon,
    },
    {
      name: t('subtractionGenerator.title'),
      link: "/gerador-subtracao",
      icon: CalculatorIcon,
    },
    {
      name: t('multiplicationGenerator.title'),
      link: "/gerador-multiplicacao",
      icon: CalculatorIcon,
    },
  ];

  const otherToolsMenuItems = [
    {
      name: t('spinWheel.title'),
      link: "/roleta-sim-nao",
      icon: PuzzlePieceIcon,
    },
    {
      name: t('luckyNumbers.title'),
      link: "/gerador-numero-da-sorte",
      icon: SparklesIcon,
    },
  ];

  const Contact =[
    {
      name: t('contact.contact'),
      link: "/contact ",
      icon: PuzzlePieceIcon,
    },
  ]

  const allMenuItems = [
    ...calculatorMenuItems,
    ...pedagogicalToolsMenuItems,
    ...otherToolsMenuItems,
  ];

  return {
    calculatorMenuItems,
    pedagogicalToolsMenuItems,
    otherToolsMenuItems,
    allMenuItems,
  };
};

export default useMenuItems;
