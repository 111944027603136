import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const CookieConsent = () => {
  const { t } = useTranslation();
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (cookieConsent === 'true') {
      setAccepted(true);
    }
  }, []);

  const acceptCookies = () => {
    setAccepted(true);
    localStorage.setItem('cookieConsent', 'true');
  };

  if (accepted) return null;

  return (
    <div className="cookie-consent fixed bottom-0 left-0 right-0 bg-[#04072e] bg-opacity-70  text-white p-6 flex justify-between items-center">
   
      <p className='ml-4 text-left'>{t('common.cookieMessage')}</p>
      <button
        onClick={acceptCookies}
        className="bg-white text-[#04072e] hover:bg-[#04072e] hover:text-white font-bold py-2 px-4 rounded mr-4"
      >
        {t('common.acceptButton')}
      </button>
      
    </div>
  );
};

export default CookieConsent;
