import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();

  const getPrivacyPolicyUrl = () => {
    const url = (() => {
      switch (i18n.language) {
        case 'es':
          return '/CookiesAndPrivacy/PrivacyES.html';
        case 'fr':
          return '/CookiesAndPrivacy/PrivacyFR.html';
        case 'en':
          return '/CookiesAndPrivacy/PrivacyEN.html';
        default:
          return '/CookiesAndPrivacy/PrivacyPT.html';
      }
    })();
    console.log(`Privacy Policy URL: ${url}`);
    return url;
  };
  

  return (
    <div>
      <iframe
        src={getPrivacyPolicyUrl()}
        title="Privacy Policy"
        width="100%"
        height="1000px"
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default PrivacyPolicy;
