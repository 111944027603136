import React, { useState } from "react";
import WheelComponent from "./WheelComponent";
import ResultAnimation from "./ResultAnimation";
import { useTranslation } from "react-i18next";

function Wheel() {
  const [winner, setWinner] = useState("");
  const [showResult, setShowResult] = useState(false);
  const { t } = useTranslation();

  const yes = t("spinWheel.yes");
  const no = t("spinWheel.no");

  // Utilizando os valores traduzidos na criação dos arrays
  const segments = Array.from({ length: 14 }, (_, i) =>
    i % 2 === 0 ? yes : no
  );
  const segColors = Array.from({ length: 14 }, (_, i) =>
    i % 2 === 0 ? "#3E5641" : "#a52422"
  );

  const onFinished = (winnerResult) => {
    console.log(winnerResult);
    setWinner(winnerResult);
    setShowResult(true); // Mostra o resultado quando a roda para
  };

  const handleSpinAgain = () => {
    console.log("Handling spin again");
    setWinner(""); // Reseta o vencedor
    setShowResult(false); // Esconde o resultado ao girar novamente
  };

  return (
    <div className=" flex flex-col justify-center p-4 mx-auto">
      <div className="flex flex-col justify-center mt-2">
      <div className="flex flex-col justify-center items-center mb-2">
        <h2 className="text-2xl font-bold mb-4">
          {t("spinWheel.title")}
        </h2>
        <p className="text-center px-4 md:px-20 lg:px-40">
          {t("spinWheel.pageDescription")}
        </p>
</div>
        <span> {t("spinWheel.description")}</span>
      </div>
      <div className="flex justify-center mt-[-60px]">
        <WheelComponent
          segments={segments}
          segColors={segColors}
          winningSegment=""
          onFinished={(winner) => onFinished(winner)}
          primaryColor="black"
          primaryColoraround="#ffffffb9"
          contrastColor="white"
          buttonText={t("spinWheel.spin")}
          isOnlyOnce={false}
          size={190}
          upDuration={30}
          downDuration={500}
        />
        {winner && (
          <ResultAnimation result={winner} onDismiss={() => setWinner("")} />
        )}
      </div>
    </div>
  );
}

export default Wheel;
