import React from "react";
import HomepageMenu from "../components/HomePageMenu";
import useMenuItems from "../components/menuitems/menuItems";

const HomePage = () => {
  const { allMenuItems } = useMenuItems();

  return (
    <div>
      <HomepageMenu menuItems={allMenuItems} />
    </div>
  );
};

export default HomePage;
