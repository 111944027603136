import React from 'react';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
  const { i18n } = useTranslation();

  const getTermsOfServiceUrl = () => {
    const url = (() => {
        switch (i18n.language) {
          case 'es':
            return '/CookiesAndPrivacy/TermsES.html';
          case 'fr':
            return '/CookiesAndPrivacy/TermsFR.html';
          case 'en':
            return '/CookiesAndPrivacy/TermsEN.html';
          default:
            return '/CookiesAndPrivacy/TermsPT.html';
        }
      })();
      console.log(`Privacy Policy URL: ${url}`);
      return url;
    };
    
  return (
    <div>
      <iframe
        src={getTermsOfServiceUrl()}
        title="Terms of Service"
        width="100%"
        height="1100px"
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default TermsOfService;
