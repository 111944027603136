import { StylesConfig } from 'react-select';

interface OptionType {
  label: string;
  value: string;
  color?: string;
}

const customStyles: StylesConfig<OptionType, boolean> = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#04052E' : 'white',
    color: state.isSelected ? 'white' : '#04052E',
    '&:hover': {
      backgroundColor: '#04052E',
      color: 'white',
    },
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: '#04052E',
    color: 'white',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data?.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data?.color,
    ':hover': {
      backgroundColor: data?.color,
      color: 'white',
    },
  }),
};

export default customStyles;
