import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Assuming you have this setup for i18n

const MeasureConverter = () => {
  // Hooks for localization
  const { t } = useTranslation();

  // State hooks for selected measure type and all measure values
  const [selectedType, setSelectedType] = useState('temperature');
  const [activeButton, setActiveButton] = useState("temperature");
  const [activeType, setActiveType] = useState("");
  const [celsius, setCelsius] = useState('');
  const [fahrenheit, setFahrenheit] = useState('');
  const [meter, setMeter] = useState('');
  const [centimeter, setCentimeter] = useState('');
  const [kilometer, setKilometer] = useState('');
  const [millimeter, setMillimeter] = useState('');
  const [feet, setFeet] = useState('');
  const [inch, setInch] = useState('');
  const [pound, setPound] = useState('');
  const [kilogram, setKilogram] = useState('');
  const [fluidOunce, setFluidOunce] = useState('');
  const [ounce, setOunce] = useState('');
  const [gram, setGram] = useState('');
  const [liter, setLiter] = useState('');
  const [milliliter, setMilliliter] = useState('');
  const [gallon, setGallon] = useState('');
  const [squareMeter, setSquareMeter] = useState('');
  const [squareFeet, setSquareFeet] = useState('');
  const [cubicMeter, setCubicMeter] = useState('');
  const [cubicFeet, setCubicFeet] = useState('');
  const [literPerSecond, setLiterPerSecond] = useState('');
  const [cubicMeterPerSecond, setCubicMeterPerSecond] = useState('');

  // Temperature conversion functions
  const convertCelsiusToFahrenheit = (celsius) => (celsius * 9 / 5) + 32;
  const convertFahrenheitToCelsius = (fahrenheit) => (fahrenheit - 32) * 5 / 9;

  // Length conversion functions
  const convertMeterToCentimeter = (meter) => meter * 100;
  const convertCentimeterToMeter = (centimeter) => centimeter / 100;
  const convertMeterToKilometer = (meter) => meter / 1000;
  const convertKilometerToMeter = (kilometer) => kilometer * 1000;
  const convertMeterToMillimeter = (meter) => meter * 1000;
  const convertMillimeterToMeter = (millimeter) => millimeter / 1000;
  const convertMillimeterToCentimeter = (millimeter) => millimeter / 100;
  const convertMillimeterToKilometer = (milliliter) => milliliter / 100000;
  const convertFeetToInch = (feet) => feet * 12;
  const convertInchToFeet = (inch) => inch / 12;
  const convertMeterToFeet = (meter) => meter * 3.28084;
  const convertMeterToInch = (meter) => meter * 39.3701;
  const convertCentimeterToInch = (centimeter) => centimeter * 0.393701;
  const convertCentimeterToFeet = (centimeter) => centimeter / 30.48;
  const convertMillimeterToFeet = (millimeter) => millimeter * 0.00328084;
  const convertMillimeterToInch = (millimeter) => millimeter * 0.0393701;
  const convertFeetToCentimeter = (feet) => feet * 30.48;
  const convertFeetToMeter = (feet) => feet * 0.3048;
  const convertFeetToMillimeter = (feet) => feet * 304.8;
  const convertFeetToKilometer = (feet) => feet * 0.0003048;
  const convertInchToCentimeter = (inch) => inch * 2.54;
  const convertInchToMeter = (inch) => inch * 0.0254;
  const convertInchToMillimeter = (inch) => inch * 25.4;
  const convertInchToKilometer = (inch) => inch * 0.0000254;
  const convertCentimeterToMillimeter = (centimeter) => centimeter * 10;
 const convertKilometerToMillimeter = (kilometer) => kilometer * 1000000;
 const convertKilometerToCentimeter = (kilometer) => kilometer * 100000;
 const convertCentimeterToKilometer = (centimeter) => centimeter / 100000;
 const convertKilometerToFeet = (kilometer) => kilometer * 3280.84;
 const convertKilometerToInch = (kilometer) => kilometer * 39370.1;

  
  // Mass conversion functions
  const convertPoundToOunce = (pound) => pound * 16;
  const convertOunceToPound = (ounce) => ounce / 16;
  const convertKilogramToPound = (kilogram) => kilogram * 2.20462;
  const convertPoundToKilogram = (pound) => pound * 0.453592;
  const convertPoundToGram = (pound) => pound * 453.592;
  const convertGramToPound = (gram) => gram / 453.592;
  const convertOunceToKilogram = (ounce) => convertPoundToKilogram(convertOunceToPound(ounce));
  const convertKilogramToOunce = (kilogram) => convertOunceToPound(convertKilogramToPound(kilogram));
  const convertOunceToGram = (ounce) => convertPoundToGram(convertOunceToPound(ounce));
  const convertGramToOunce = (gram) => convertOunceToPound(convertGramToPound(gram));
  const convertKilogramToGram =(kilogram) => kilogram * 1000;
const convertGramToKilogram = (gram) => gram / 1000;

  // Volume conversion functions
  const convertLiterToMilliliter = (liter) => liter * 1000;
  const convertMilliliterToLiter = (milliliter) => milliliter / 1000;
  const convertGallonToLiter = (gallon) => gallon * 3.78541;
  const convertLiterToGallon = (liter) => liter / 3.78541;
  const convertGallonToCubicMeter = (gallon) => convertGallonToLiter(gallon) / 1000;
  const convertGallonToCubicFeet = (gallon) => convertGallonToLiter(gallon) * 0.133681;
  const convertLiterToCubicMeter = (liter) => liter / 1000;
  const convertCubicMeterToLiter = (cubicMeter) => cubicMeter * 1000;
  const convertLiterToCubicFeet = (liter) => liter * 0.0353147;
  const convertCubicFeetToLiter = (cubicFeet) => cubicFeet * 28.3168;
  const convertGallonToMilliliter = (gallon) => gallon * 3785.41;
  const convertCubicMeterToGallon = (cubicMeter) => cubicMeter * 264.172;
  const convertMilliliterToCubicFeet = (milliliter) => milliliter * 3.53147e-5;
  const convertMilliliterToGallon = (milliliter) => milliliter * 0.000264172;
  const convertMilliliterToCubicMeter = (milliliter) => milliliter / 1000000;
  const convertCubicMeterToMilliliter = (cubicMeter) => cubicMeter * 1000000;
  const convertCubicFeetToGallon = (cubicFeet) => cubicFeet * 7.48052;
  const convertCubicFeetToMilliliter = (cubicFeet) => cubicFeet * 28316.8;
  const convertFluidOunceToLiter = (fluidOunce) => fluidOunce * 0.0295735;
  const convertFluidOunceToMilliliter = (fluidOunce) => fluidOunce * 29.5735;
  const convertFluidOunceToGallon = (fluidOunce) => fluidOunce * 0.0078125;
  const convertFluidOunceToCubicMeter = (fluidOunce) => fluidOunce * 2.95735e-5;
  const convertFluidOunceToCubicFeet = (fluidOunce) => fluidOunce * 0.00104438;
  const convertLiterToFluidOunce = (liter) => liter / 0.0295735;
  const convertMilliliterToFluidOunce = (milliliter) => milliliter / 29.5735;
  const convertGallonToFluidOunce = (gallon) => gallon / 0.0078125;
  const convertCubicMeterToFluidOunce = (cubicMeter) => cubicMeter / 2.95735e-5;
  const convertCubicFeetToFluidOunce = (cubicFeet) => cubicFeet / 0.00104438;


  // Area conversion functions
  const convertSquareMetersToSquareFeet = (squareMeters) => squareMeters * 10.7639;
  const convertSquareFeetToSquareMeters = (squareFeet) => squareFeet * 0.092903;

  // Volume flow rate conversion functions
  const convertLiterPerSecondToCubicMeterPerSecond = (literPerSecond) => literPerSecond * 0.001;
  const convertCubicMeterPerSecondToLiterPerSecond = (cubicMeterPerSecond) => cubicMeterPerSecond / 0.001;

  // Cubic conversion functions
  const convertCubicMetersToCubicFeet = (cubicMeters) => cubicMeters * 35.3147;
  const convertCubicFeetToCubicMeters = (cubicFeet) => cubicFeet * 0.0283168;


 // Event handlers for input changes

// Type selection handler
const handleTypeChange = (e) => {
  setSelectedType(e.target.value);
  setActiveType(e.target.value);
  setActiveButton(e.target.value);
};

// Temperature input handlers
const handleCelsiusChange = (e) => {
  const celsiusValue = e.target.value;
  setCelsius(celsiusValue);
  setFahrenheit(convertCelsiusToFahrenheit(celsiusValue));
};

const handleFahrenheitChange = (e) => {
  const fahrenheitValue = e.target.value;
  setFahrenheit(fahrenheitValue);
  setCelsius(convertFahrenheitToCelsius(fahrenheitValue));
};

// Volume flow rate input handlers
const handleLiterPerSecondChange = (e) => {
  const value = e.target.value;
  setLiterPerSecond(value);
  if (value !== '') {
    const cubicMeterValue = convertLiterPerSecondToCubicMeterPerSecond(parseFloat(value));
    setCubicMeterPerSecond(cubicMeterValue.toFixed(2));
  } else {
    setCubicMeterPerSecond('');
  }
};

const handleCubicMeterPerSecondChange = (e) => {
  const value = e.target.value;
  setCubicMeterPerSecond(value);
  if (value !== '') {
    const literPerSecondValue = convertCubicMeterPerSecondToLiterPerSecond(parseFloat(value));
    setLiterPerSecond(literPerSecondValue.toFixed(2));
  } else {
    setLiterPerSecond('');
  }
};

// Length input handlers
const handleMeterChange = (e) => {
  const meterValue = e.target.value;
  setMeter(meterValue);
  setCentimeter(convertMeterToCentimeter(meterValue));
  setKilometer(convertMeterToKilometer(meterValue));
  setMillimeter(convertMeterToMillimeter(meterValue));
  setFeet(convertMeterToFeet(meterValue));
  setInch(convertMeterToInch(meterValue));
};

const handleCentimeterChange = (e) => {
  const centimeterValue = e.target.value;
  setCentimeter(centimeterValue);
  setMeter(convertCentimeterToMeter(centimeterValue));
  setFeet(convertCentimeterToFeet(centimeterValue));
  setInch(convertCentimeterToInch(centimeterValue));
 setMillimeter(convertCentimeterToMillimeter(centimeterValue));
 setKilometer(convertCentimeterToKilometer(centimeterValue));
};

const handleKilometerChange = (e) => {
  const kilometerValue = e.target.value;
  setKilometer(kilometerValue);
  setMeter(convertKilometerToMeter(kilometerValue));
  setCentimeter(convertKilometerToCentimeter(kilometerValue));
  setMillimeter(convertKilometerToMillimeter(kilometerValue));
  setInch(convertKilometerToInch(kilometerValue));
  setFeet(convertKilometerToFeet(kilometerValue));
};

const handleMillimeterChange = (e) => {
  const millimeterValue = e.target.value;
  setMillimeter(millimeterValue);
  setMeter(convertMillimeterToMeter(millimeterValue));
  setInch(convertMillimeterToInch(millimeterValue))
  setFeet(convertMillimeterToFeet(millimeterValue))
  setCentimeter(convertMillimeterToCentimeter(millimeterValue))
  setKilometer(convertMillimeterToKilometer(millimeterValue))

};

const handleFeetChange = (e) => {
  const feetValue = e.target.value;
  setFeet(feetValue);
  setCentimeter(convertFeetToCentimeter(feetValue));
  setMeter(convertFeetToMeter(feetValue));
  setMillimeter(convertFeetToMillimeter(feetValue));
  setKilometer(convertFeetToKilometer(feetValue));
  setInch(convertFeetToInch(feetValue));
};

const handleInchChange = (e) => {
  const inchValue = e.target.value;
  setInch(inchValue);
  setCentimeter(convertInchToCentimeter(inchValue));
  setMeter(convertInchToMeter(inchValue));
  setMillimeter(convertInchToMillimeter(inchValue));
  setKilometer(convertInchToKilometer(inchValue));
  setFeet(convertInchToFeet(inchValue));
};


// Mass input handlers
const handlePoundChange = (e) => {
  const poundValue = e.target.value;
  setPound(poundValue);
  setKilogram(convertPoundToKilogram(poundValue));
  setGram(convertPoundToGram(poundValue));
  setOunce(convertPoundToOunce(poundValue))
};

const handleKilogramChange = (e) => {
  const kilogramValue = e.target.value;
  setKilogram(kilogramValue);
  setPound(convertKilogramToPound(kilogramValue));
  setOunce(convertKilogramToOunce(kilogramValue))
  setGram(convertKilogramToGram(kilogramValue));
};

const handleOunceChange = (e) => {
  const ounceValue = e.target.value;
  setOunce(ounceValue);
  setPound(convertOunceToPound(ounceValue));
  setGram(convertOunceToGram(ounceValue));
  setKilogram(convertOunceToKilogram(ounceValue));
};

const handleGramChange = (e) => {
  const gramValue = e.target.value;
  setGram(gramValue);
  setPound(convertGramToPound(gramValue));
  setOunce(convertGramToOunce(gramValue));
  setKilogram(convertGramToKilogram(gramValue));
};

// Volume input handlers
const handleLiterChange = (e) => {
  const literValue = e.target.value;
  setLiter(literValue);
  setMilliliter(convertLiterToMilliliter(literValue));
  setGallon(convertLiterToGallon(literValue));
  setCubicMeter(convertLiterToCubicMeter(literValue));
  setCubicFeet(convertLiterToCubicFeet(literValue));
  setFluidOunce(convertLiterToFluidOunce(literValue));
};

const handleMilliliterChange = (e) => {
  const milliliterValue = e.target.value;
  setMilliliter(milliliterValue);
  setLiter(convertMilliliterToLiter(milliliterValue));
  setCubicFeet(convertMilliliterToCubicFeet(milliliterValue));
  setGallon(convertMilliliterToGallon(milliliterValue));
  setCubicMeter(convertMilliliterToCubicMeter(milliliterValue));
  setFluidOunce(convertMilliliterToFluidOunce(milliliterValue));
};

const handleGallonChange = (e) => {
  const gallonValue = e.target.value;
  setGallon(gallonValue);
  setLiter(convertGallonToLiter(gallonValue));
  setCubicMeter(convertGallonToCubicMeter(gallonValue));
  setCubicFeet(convertGallonToCubicFeet(gallonValue));
  setMilliliter(convertGallonToMilliliter(gallonValue));  
  setFluidOunce(convertGallonToFluidOunce(gallonValue));
};

const handleFluidOunceChange = (e) => {
  const fluidOunceValue = e.target.value;
  setFluidOunce(fluidOunceValue);
  setLiter(convertFluidOunceToLiter(fluidOunceValue));
  setCubicMeter(convertFluidOunceToCubicMeter(fluidOunceValue));
  setCubicFeet(convertFluidOunceToCubicFeet(fluidOunceValue));
  setMilliliter(convertFluidOunceToMilliliter(fluidOunceValue));
  setGallon(convertFluidOunceToGallon(fluidOunceValue));
};

// Area input handlers
const handleSquareMeterChange = (e) => {
  const squareMeterValue = e.target.value;
  setSquareMeter(squareMeterValue);
  setSquareFeet(convertSquareMetersToSquareFeet(squareMeterValue));
};

const handleSquareFeetChange = (e) => {
  const squareFeetValue = e.target.value;
  setSquareFeet(squareFeetValue);
  setSquareMeter(convertSquareFeetToSquareMeters(squareFeetValue));
};

// Cubic input handlers
const handleCubicMeterChange = (e) => {
  const cubicMeterValue = e.target.value;
  setCubicMeter(cubicMeterValue);
  setCubicFeet(convertCubicMetersToCubicFeet(cubicMeterValue));
  setLiter(convertCubicMeterToLiter(cubicMeterValue));
  setGallon(convertCubicMeterToGallon(cubicMeterValue));
  setMilliliter(convertCubicMeterToMilliliter(cubicMeterValue));
  setFluidOunce(convertCubicMeterToFluidOunce(cubicMeterValue));
  
};

const handleCubicFeetChange = (e) => {
  const cubicFeetValue = e.target.value;
  setCubicFeet(cubicFeetValue);
  setCubicMeter(convertCubicFeetToCubicMeters(cubicFeetValue));
  setLiter(convertCubicFeetToLiter(cubicFeetValue));
  setGallon(convertCubicFeetToGallon(cubicFeetValue));
  setMilliliter(convertCubicFeetToMilliliter(cubicFeetValue));
  setFluidOunce(convertCubicFeetToFluidOunce(cubicFeetValue));
};


return (
  <div className="p-4 mx-auto bg-white rounded-xl flex flex-col items-center space-y-6 lg:max-w-[50%]">
    <div className="flex-row justify-center items-center mb-2 ">
      <h2 className="text-2xl font-bold mb-4">{t("measureConverter.title")}</h2>
      <p className="text-center">{t("measureConverter.pageDescription")}</p>
    </div>
    <div className='flex flex-col space-y-10 border-2 border-darkblue-100 rounded-md sm:w-full sm:p-2 lg:p-6 '>
      <div className="flex flex-wrap justify-evenly p-2 border-b-[1px] border-darkblue-100">
        <button className={`flex rounded-md p-4 ${activeButton === "temperature" ? "bg-[#04052E] text-white" : "hover:bg-[#04052E] hover:bg-opacity-30 hover:text-[#04052E]"}`} value="temperature" onClick={handleTypeChange}>
          {t("measureConverter.temperature")}
        </button>
        <button className={`flex p-4 rounded-md  ${activeButton === "length" ? "bg-[#04052E] text-white" : "hover:bg-[#04052E] hover:bg-opacity-30 hover:text-[#04052E]"}`} value="length" onClick={handleTypeChange}>
          {t("measureConverter.length")}
        </button>
        <button className={`flex p-4 rounded-md ${activeButton === "weight" ? "bg-[#04052E] text-white" : "hover:bg-[#04052E] hover:bg-opacity-30 hover:text-[#04052E]"}`} value="weight" onClick={handleTypeChange}>
          {t("measureConverter.weight")}
        </button>
        <button className={`flex p-4 rounded-md ${activeButton === "volume" ? "bg-[#04052E] text-white" : "hover:bg-[#04052E] hover:bg-opacity-30 hover:text-[#04052E]"}`} value="volume" onClick={handleTypeChange}>
          {t("measureConverter.volume")}
        </button>
        <button className={`flex p-4 rounded-md ${activeButton === "area" ? "bg-[#04052E] text-white" : "hover:bg-[#04052E] hover:bg-opacity-30 hover:text-[#04052E]"}`} value="area" onClick={handleTypeChange}>
          {t("measureConverter.area")}
        </button>
        <button className={`flex p-4 rounded-md ${activeButton === "volumeFlow" ? "bg-[#04052E] text-white" : "hover:bg-[#04052E] hover:bg-opacity-30 hover:text-[#04052E]"}`} value="volumeFlow" onClick={handleTypeChange}>
          {t("measureConverter.volumeFlow")}
        </button>
      </div>
      <div className='flex flex-col items-center space-y-6'>
        {selectedType === "temperature" && (
          <div className="w-full">
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.celsius")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={celsius}
                onChange={handleCelsiusChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.fahrenheit")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={fahrenheit}
                onChange={handleFahrenheitChange}
              />
            </div>
          </div>
        )}
        {selectedType === "length" && (
          <div className="w-full">
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.meter")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={meter}
                onChange={handleMeterChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.centimeter")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={centimeter}
                onChange={handleCentimeterChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.kilometer")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={kilometer}
                onChange={handleKilometerChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.millimeter")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={millimeter}
                onChange={handleMillimeterChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.feet")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={feet}
                onChange={handleFeetChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.inch")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={inch}
                onChange={handleInchChange}
              />
            </div>
          </div>
        )}
        {selectedType === "weight" && (
          <div className="w-full">
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.gram")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={gram}
                onChange={handleGramChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.kilogram")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={kilogram}
                onChange={handleKilogramChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.ounce")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={ounce}
                onChange={handleOunceChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.pound")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={pound}
                onChange={handlePoundChange}
              />
            </div>
          </div>
        )}
        {selectedType === "volume" && (
          <div className="w-full">
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.liter")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={liter}
                onChange={handleLiterChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.milliliter")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={milliliter}
                onChange={handleMilliliterChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.gallon")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={gallon}
                onChange={handleGallonChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.fluidOunce")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={fluidOunce}
                onChange={handleFluidOunceChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.cubicMeter")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={cubicMeter}
                onChange={handleCubicMeterChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.cubicFeet")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={cubicFeet}
                onChange={handleCubicFeetChange}
              />
            </div>
          </div>
        )}
        {selectedType === "area" && (
          <div className="w-full">
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.squareMeter")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={squareMeter}
                onChange={handleSquareMeterChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.squareFeet")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={squareFeet}
                onChange={handleSquareFeetChange}
              />
            </div>
          </div>
        )}
        {selectedType === "volumeFlow" && (
          <div className="w-full">
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.literPerSecond")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={literPerSecond}
                onChange={handleLiterPerSecondChange}
              />
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-center">{t("measureConverter.cubicMeterPerSecond")}</h3>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                value={cubicMeterPerSecond}
                onChange={handleCubicMeterPerSecondChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

};

export default MeasureConverter;