import React, { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import ContasPdfComponent from "./ContasPdfGenerator";

const AdditionGenerator = () => {
  const [quantidadeDigitos, setQuantidadeDigitos] = useState(2);
  const [quantidadeContas, setQuantidadeContas] = useState(10);
  const [contasGeradas, setContasGeradas] = useState([]);
  const [respostasUsuario, setRespostasUsuario] = useState([]);
  const [exibirGabarito, setExibirGabarito] = useState(false);
  const { t } = useTranslation();

  const contaSinal = "+";

  const gerarContas = () => {
    const contas = [];

    for (let i = 0; i < quantidadeContas; i++) {
      const numero1 = Math.floor(Math.random() * 10 ** quantidadeDigitos);
      const numero2 = Math.floor(Math.random() * 10 ** quantidadeDigitos);
      const resultado = numero1 + numero2;
      contas.push({ numero1, numero2, resultado });
    }
    setContasGeradas(contas);
    setRespostasUsuario(new Array(quantidadeContas).fill(""));
  };

  const mostrarGabarito = () => {
    setRespostasUsuario((prevRespostas) =>
      contasGeradas.map((conta) => conta.resultado.toString())
    );
    setExibirGabarito(true);
  };

  const limparRespostas = () => {
    setRespostasUsuario((prevRespostas) => prevRespostas.map(() => ""));
  };

  const esconderGabarito = () => {
    limparRespostas();
    setExibirGabarito(false);
  };

  return (
    <div className="flex flex-col justify-center p-4 mx-auto max-w-4xl">
      <div className="flex flex-col items-center mb-2">
        <h2 className="text-2xl font-bold mb-4">{t("additionGenerator.title")}</h2>
        <p className="text-center">{t("additionGenerator.pageDescription")}</p>
        </div>
      <div className="border-2 rounded-md p-4 mr-auto ml-auto">
        <div className="flex flex-row md:flex-row md:justify-evenly mb-2">
          <div className="flex flex-1 flex-row justify-evenly space-x-10 mb-2 md:w-1/3">
            <div className="space-y-4 w-auto justify-center text-center">
              <label
                className="flex justify-center items-center text-center whitespace-pre-line"
                htmlFor="quantidadeDigitos"
              >
                {t("additionGenerator.quantityOfDigits")}
              </label>
              <input
                className="input-field border-4 border-[#04072e] rounded-md pl-4 text-center font-semibold w-[50%] align-middle "
                type="number"
                value={quantidadeDigitos}
                onChange={(e) => setQuantidadeDigitos(e.target.value)}
              />
            </div>
            <div className="space-y-4 w-auto">
              <label
                className="flex justify-center items-center text-center whitespace-pre-line"
                htmlFor="quantidadeContas"
              >
                {t("additionGenerator.quantityOfOperations")}
              </label>
              <input
                className="input-field border-4 border-[#04072e] rounded-md pl-4 text-center font-semibold w-[50%] align-middle "
                type="number"
                value={quantidadeContas}
                onChange={(e) => setQuantidadeContas(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button
          className="bg-[#04052E] bg-opacity-90 text-white px-4 py-2 mt-4 rounded-md w-full"
          onClick={gerarContas}
        >
          {t("additionGenerator.generateOperations")}
        </button>
      </div>
      <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 mt-4">
        <button
          onClick={exibirGabarito ? esconderGabarito : mostrarGabarito}
          className="bg-[#04052E] bg-opacity-90 text-white py-2 rounded-md w-full md:w-1/2"
        >
          {exibirGabarito
            ? t("additionGenerator.hideAnswerKey")
            : t("additionGenerator.showAnswerKey")}
        </button>
        <PDFDownloadLink
          className="bg-[#04052E] bg-opacity-90 text-white px-4 py-2 rounded-md w-full md:w-1/2"
          document={
            <ContasPdfComponent
              contasGeradas={contasGeradas}
              exibirGabarito={exibirGabarito}
              title={
                exibirGabarito
                  ? t("additionGenerator.pdfTitleWithAnswerKey", {
                      count: quantidadeContas,
                    })
                  : t("additionGenerator.pdfTitle", {
                      count: quantidadeContas,
                    })
              }
              sinal={contaSinal}
            />
          }
          fileName={
            exibirGabarito
              ? t("additionGenerator.pdfTitleWithAnswerKey", {
                  count: quantidadeContas,
                })
              : t("additionGenerator.pdfTitle", {
                  count: quantidadeContas,
                })
          }
        >
          {({ loading }) =>
            loading ? t("common.generatingPDF") : t("common.generatePDF")
          }
        </PDFDownloadLink>
      </div>
      <ul className="mt-4 grid gap-4">
  {contasGeradas.map((conta, index) => (
    <li key={index} className="grid grid-cols-[40px_60px_20px_60px_20px_40px] justify-center items-center gap-2">
      <span className="text-gray-500 font-bold text-xs border-2 w-8 h-8 flex justify-center items-center rounded-full">
        {index + 1}
      </span>
      <span className="text-black text-lg">{conta.numero1}</span>
      <span className="text-black text-lg">{contaSinal}</span>
      <span className="text-black text-lg">{conta.numero2}</span>
      <span className="text-black text-lg">=</span>
      <input
        className="input-field border-2 border-[#04052E] w-16 h-16 text-center"
        type="text"
        pattern="[0-9]*"
        value={respostasUsuario[index]}
        onChange={(e) =>
          setRespostasUsuario((prevRespostas) =>
            prevRespostas.map((prevResposta, i) =>
              i === index ? e.target.value : prevResposta
            )
          )
        }
        disabled={exibirGabarito}
      />
    </li>
  ))}
</ul>

    </div>
  );
};

export default AdditionGenerator;
