import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import CalculatorMenu from "./menuitems/CalculatorMenu";
import PedagogicalToolsMenu from "./menuitems/PedagogicalToolsMenu";
import OtherToolsMenu from "./menuitems/OtherToolsMenu";
import { GlobeAmericasIcon, ChevronDownIcon, Bars3Icon, XCircleIcon } from "@heroicons/react/20/solid";
import { ChatBubbleBottomCenterTextIcon, HomeIcon } from "@heroicons/react/24/outline";


function Header() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const getLanguageLabel = (lng) => {
    switch (lng) {
      case 'en':
        return 'English';
      case 'pt':
        return 'Português';
      case 'es':
        return 'Español';
      case 'fr':
        return 'Français';
      default:
        return 'Language';
    }
  };

  const goToHome = () => {
    navigate("/", { replace: true });
  };

  const goToContact = () => {
    navigate("/contact", { replace: true });
  };

  return (
    <div className="flex flex-col md:flex-row md:justify-between items-center border-b-[1px] border-[#F7FFF7] shadow-sm p-4">
      {/* Layout Mobile */}
      <div className="flex justify-between items-center w-full md:hidden">
        <button onClick={goToHome} className="flex items-center">
          <HomeIcon className="w-6 h-6" />
          <h1 className="ml-2 text-[#04052E]">MigusLab</h1>
        </button>
        <div className="flex items-center">
          <HeadlessMenu as="div" className="relative mr-4">
            <HeadlessMenu.Button className="flex items-left focus:outline-none">
              <GlobeAmericasIcon className="w-6 h-6" />
            </HeadlessMenu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <HeadlessMenu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
                <div className="py-2 rounded-md bg-white shadow-xs">
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => changeLanguage('en')}
                        className={`${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-md text-gray-700`}
                      >
                        English
                      </button>
                    )}
                  </HeadlessMenu.Item>
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => changeLanguage('pt')}
                        className={`${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-md text-gray-700`}
                      >
                        Português
                      </button>
                    )}
                  </HeadlessMenu.Item>
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => changeLanguage('es')}
                        className={`${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-md text-gray-700`}
                      >
                        Español
                      </button>
                    )}
                  </HeadlessMenu.Item>
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => changeLanguage('fr')}
                        className={`${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-md text-gray-700`}
                      >
                        Français
                      </button>
                    )}
                  </HeadlessMenu.Item>
                </div>
              </HeadlessMenu.Items>
            </Transition>
          </HeadlessMenu>
          <HeadlessMenu as="div" className="relative">
            <HeadlessMenu.Button className="focus:outline-none">
              {({ open }) => (
                open ? <XCircleIcon className="w-6 h-6" /> : <Bars3Icon className="w-6 h-6" />
              )}
            </HeadlessMenu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <HeadlessMenu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                <div className="py-2 rounded-md bg-white shadow-xs">
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        className="block w-full text-md text-gray-700"
                      >
                        <CalculatorMenu />
                      </button>
                    )}
                  </HeadlessMenu.Item>
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        className="block w-full  text-md text-gray-700"
                      >
                        <PedagogicalToolsMenu />
                      </button>
                    )}
                  </HeadlessMenu.Item>
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        className="block w-full text-md text-gray-700"
                      >
                        <OtherToolsMenu />
                      </button>
                    )}
                  </HeadlessMenu.Item>
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        className="flex items-center justify-center w-full px-1 py-6 text-md font-medium text-gray-700"
                        onClick={goToContact}
                      >
                        <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-2" />
                        {t("contact.contact_us")}
                      </button>
                    )}
                  </HeadlessMenu.Item>
                </div>
              </HeadlessMenu.Items>
            </Transition>
          </HeadlessMenu>
        </div>
      </div>

      {/* Layout Desktop */}
      <div className="hidden md:flex justify-between items-center w-full">
        <button onClick={goToHome} className="flex items-center mb-4 md:mb-0">
          <HomeIcon className="w-6 h-6" />
          <h1 className="ml-2 text-[#04052E]">MigusLab</h1>
        </button>
        <div className="md:flex md:space-x-4">
          <CalculatorMenu />
          <PedagogicalToolsMenu />
          <OtherToolsMenu />
          <button
            className="flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium text-[#04052E] hover:bg-[#04052E] hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
            onClick={goToContact}
          >
            <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-2" />
            {t("contact.contact_us")}
          </button>
        </div>
        <div className="relative flex items-center text-[#04052E] hover:bg-[#04052E] hover:text-white rounded-xl">
          <HeadlessMenu as="div" className="relative">
            <HeadlessMenu.Button className="flex items-center space-x-2 focus:outline-none">
              <GlobeAmericasIcon className="h-5 w-5" />
              <span>{getLanguageLabel(i18n.language)}</span>
              <ChevronDownIcon className="h-4 w-4" />
            </HeadlessMenu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <HeadlessMenu.Items className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
                <div className="py-2 rounded-md bg-white shadow-xs">
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => changeLanguage('en')}
                        className={`${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-md text-gray-700`}
                      >
                        English
                      </button>
                    )}
                  </HeadlessMenu.Item>
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => changeLanguage('pt')}
                        className={`${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-md text-gray-700`}
                      >
                        Português
                      </button>
                    )}
                  </HeadlessMenu.Item>
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => changeLanguage('es')}
                        className={`${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-md text-gray-700`}
                      >
                        Español
                      </button>
                    )}
                  </HeadlessMenu.Item>
                  <HeadlessMenu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => changeLanguage('fr')}
                        className={`${active ? 'bg-gray-100' : ''} block w-full px-4 py-2 text-md text-gray-700`}
                      >
                        Français
                      </button>
                    )}
                  </HeadlessMenu.Item>
                </div>
              </HeadlessMenu.Items>
            </Transition>
          </HeadlessMenu>
        </div>
      </div>
    </div>
  );
}

export default Header;
