import React from "react";
import { Menu } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { AcademicCapIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import useMenuItems from "./menuItems";
import { useTranslation } from "react-i18next";


function handleMenuItemClick(navigate, item) {
  navigate(item.link);

}

function PedagogicalToolsMenu() {
  const navigate = useNavigate();
  const { pedagogicalToolsMenuItems } = useMenuItems();
  const { t } = useTranslation();
  
  return (
     <Menu as="div" className="relative inline-block">
      <div>
        <Menu.Button className="flex items-center justify-center rounded-md px-6 py-8 text-md font-medium text-[#04052E] hover:bg-[#04052E] hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
          <AcademicCapIcon className="h-5 w-5 mr-2" />
          {t("menu.pedagogicalToolsTitle")}
          <ChevronDownIcon
            className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Menu.Items className="absolute right-0 w-full mt-2 text-left origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
        <div className="py-1">
          {pedagogicalToolsMenuItems.map((item, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <button
                  className={`${
                    active
                      ? "bg-[#04052E] bg-opacity-90 text-white"
                      : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-md text-left`}
                  onClick={() => handleMenuItemClick(navigate, item)}
                >
                  {React.createElement(item.icon, {
                    className: "h-5 w-5 mr-2",
                  })}
                  {item.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  );
}

export default PedagogicalToolsMenu;
