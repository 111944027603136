import React from "react";
import { useTransition } from "react-spring";

const BackgroundTransition = ({ result }) => {
  const transitions = useTransition(result, {
    from: { background: "rgba(255, 255, 255, 0)" },
    enter: { background: "rgba(255, 255, 255, 0.8)" },
    leave: { background: "rgba(255, 255, 255, 0)" },
    config: { duration: 500 },
  });

  return transitions((styles, item) => <div style={styles} />);
};

export default BackgroundTransition;
