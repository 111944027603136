import React, { useMemo } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register(
  {
    family: "Roboto",
    src: "http://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf",
  },
  {
    family: "Lora",
    src: "http://fonts.gstatic.com/s/lora/v10/4A-myfZX6oDr9CtSTkTGig.ttf",
  }
);

const styles = StyleSheet.create({
  conta: {
    flexDirection: "row",
    alignItems: "center",
  },
  numero: {
    fontSize: "14pt",
    width: "30px",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "15px",
    fontFamily: "Roboto",
    fontWeight: "light",
    marginLeft: "10px",
  },
  resultado: {
    fontSize: "14pt",
    border: "2pt",
    height: "40px",
    minWidth: "50px",
    maxWidth: "60px",
    marginLeft: "10px",
    marginBottom: "15px",
    textAlign: "center",
    paddingTop: "12px",
    fontFamily: "Roboto",
    paddingLeft: "6px",
    paddingRight: "6px",
    fontWeight: "light",
  },
  title: {
    fontSize: "24pt",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "30px",
    marginTop: "20px",
    fontFamily: "Times-Roman",
    border: "2px",
    borderStyle: "dashed",
    padding: "10px",
    marginLeft: "50px",
    marginRight: "50px",
  },

  section: {
    marginLeft: "5%",
    marginRight: "5%",
  },
  contasContainer: {
    marginTop: "20pt",
  },
  page: {
    padding: "20pt",
    flexDirection: "column",
  },
  operationsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    maxHeight: "50%",
  },
  blackLine: {
    display: "flex-start",
    width: "45%",
    borderRight: "2px",
    height: "90%",
  },

  operationCount: {
    fontSize: "8px",
    textAlign: "center",
    border: "1px",
    borderColor: "gray",
    color: "gray",
    borderRadius: "100px",
    height: "24px",
    width: "24px",
    paddingTop: "7px",
    marginRight: "10px",
    marginBottom: "14px",
  },

  sinal: {
    display: "flex",
    fontSize: "14pt",
    maxWidth: "5px",
    marginRight: "6px",
    marginLeft: "6px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "15px",
    fontFamily: "Roboto",
    fontWeight: "light",
  },
});

const ContaPdf = ({
  conta,
  exibirGabarito,
  index,
  paginaAtual,
  contasPorPagina,
  sinal,
}) => {
  const UltimaContaDaPagina = (index + 1) % contasPorPagina === 0;

  return (
    <View>
      <View
        style={[
          styles.operationsContainer,
          UltimaContaDaPagina && { marginBottom: "16pt" },
        ]}
      >
        <Text key={index} style={styles.operationCount}>
          {index + 1}
        </Text>

        <Text style={styles.numero}>{conta.numero1}</Text>

        <Text style={styles.sinal}>{sinal}</Text>

        <Text style={styles.numero}>{conta.numero2}</Text>

        <Text style={styles.sinal}>=</Text>

        <Text style={styles.resultado}>
          {exibirGabarito ? conta.resultado : " "}
        </Text>
      </View>
    </View>
  );
};

const ContasPdfComponent = ({
  contasGeradas,
  exibirGabarito,
  title,
  sinal,
}) => {
  const contasPorPagina = 12; // Número de contas por página

  const paginasDeContas = useMemo(() => {
    const paginas = [];
    let contasDaPagina = [];

    contasGeradas.forEach((conta, index) => {
      contasDaPagina.push(
        <ContaPdf
          key={index}
          conta={conta}
          exibirGabarito={exibirGabarito}
          index={index}
          paginaAtual={Math.floor(index / contasPorPagina) + 1}
          contasPorPagina={contasPorPagina}
          sinal={sinal}
        />
      );

      if ((index + 1) % contasPorPagina === 0) {
        paginas.push(contasDaPagina);
        contasDaPagina = [];
      }
    });

    if (contasDaPagina.length > 0) {
      paginas.push(contasDaPagina);
    }

    return paginas;
  }, [contasGeradas, exibirGabarito, sinal]);

  return (
    <Document>
      {paginasDeContas.map((contasDaPagina, paginaIndex) => (
        <Page key={paginaIndex} size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>{title}</Text>
            <View style={styles.blackLine}>
              <View style={styles.contasContainer}>{contasDaPagina}</View>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default ContasPdfComponent;
