import React from "react";
import { useTranslation } from "react-i18next";
import * as Excel from "exceljs";
import { saveAs } from 'file-saver';

const ExchangeRateComponent = ({ exchangeRates, convertedAmounts, loading, error, days, setDays, sourceCurrency, targetCurrency }) => {
  const { t } = useTranslation();

  const handleExportToExcel = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Exchange Rates');

    // Headers
    worksheet.addRow(["Date", `Exchange Rate - ${sourceCurrency}`, `Converted Amount - ${targetCurrency}`]);

    worksheet.columns = [
      { header: t("exchangeRateComponent.date"), width: 15 },
      { header: `${t("exchangeRateComponent.exchangeRate")} - ${sourceCurrency}`, width: 25},
      { header: `${t("exchangeRateComponent.convertedAmount")} - ${targetCurrency}`, width: 25 },
    ];

    // Data
    convertedAmounts.forEach((amount, index) => {
      worksheet.addRow([
        amount.date,
        exchangeRates[index].ask,
        parseFloat(amount.convertedAmount) // Ensure the value is a number
      ]);
    });

    // Apply styles
    const titleStyle = {
      font: { bold: true, size: 12, color: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: 'center' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ff04052e' }
      }
    };

    const numFmtStr = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';

    worksheet.getRow(1).font = titleStyle.font;
    worksheet.getRow(1).alignment = titleStyle.alignment;
    worksheet.getRow(1).eachCell((cell, colNumber) => {
      if (cell.value !== undefined && cell.value !== null) {
        cell.fill = titleStyle.fill;
      }
    });
    worksheet.getColumn(3).numFmt = numFmtStr;

    // Save workbook as Excel file
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'exchange_rates.xlsx');
    });
  };

  const handleDaysChange = (event) => {
    setDays(event.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="flex flex-col p-4 justify-center items-center mx-auto max-w-5xl">
      <h2 className="text-2xl font-bold mb-4">
        {t("exchangeRateComponent.title")}
      </h2>

      <div className="flex items-center mb-4">
        <label htmlFor="days" className="mr-2">
          {t("exchangeRateComponent.numberOfDays")}
        </label>
        <select id="days" value={days} onChange={handleDaysChange} className="border p-2 rounded-md">
         
          <option value="7">7</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="60">60</option>
          <option value="90">90</option>
        </select>
      </div>

      <div className="overflow-x-auto w-full">
        <table className="min-w-full bg-white border border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-2 px-4 border">
                {t("exchangeRateComponent.date")}
              </th>
              <th className="py-2 px-4 border">
                {t("exchangeRateComponent.exchangeRate")} - {sourceCurrency}
              </th>
              <th className="py-2 px-4 border">
                {t("exchangeRateComponent.convertedAmount")} - {targetCurrency}
              </th>
            </tr>
          </thead>
          <tbody>
            {convertedAmounts.map((amount, index) => (
              <tr key={index} className="border">
                <td className="py-2 px-4 border">{amount.date}</td>
                <td className="py-2 px-4 border">{exchangeRates[index].ask}</td>
                <td className="py-2 px-4 border">{parseFloat(amount.convertedAmount).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        className="bg-[#04052E] text-white p-2 rounded-md cursor-pointer mt-4"
        onClick={handleExportToExcel}
      >
        {t("common.downloadExcel")}
      </button>
    </div>
  );
};

export default ExchangeRateComponent;
